<template>
  <div class="container">
    <br>
    <b-alert class="alert-page-title" show variant="secondary">
      <h4 class="alert-heading">
        {{ $t('SEARCH.title') }}
      </h4>

      <p class="mb-0">
        {{ $t('SEARCH.subtitle') }}
      </p>
    </b-alert>

    <b-modal
      id="modalAddFavorite"
      ref="modalAddFavorite"
      :title="$t('SEARCH.collection_modal.title')"
      :hide-footer="true"
    >
      <form @submit.stop.prevent="handleSubmit">
        <div class="intro">
          <div v-if="favorite.favorable_type == 'Match'">
            {{ $t('SEARCH.collection_modal.match') }}
          </div>
          <div v-if="favorite.favorable_type == 'MatchRound'">
            {{ $t('SEARCH.collection_modal.match_round') }}
          </div>
        </div>

        <b-form-input
          type="text"
          :placeholder="$t('SEARCH.collection_modal.name_placeholder')"
          v-model="favorite.name"
        ></b-form-input>

        <b-form-input type="text" placeholder="descricao" v-model="favorite.description"></b-form-input>

        <b-form-select
          id="exampleInput3"
          :options="collections"
          value-field="id"
          text-field="name"
          required
          v-model="favorite.collection"
        ></b-form-select>

        <b-btn type="submit" variant="primary">
          {{ $t('SEARCH.collection_modal.submit') }}
        </b-btn>
      </form>
    </b-modal>

    <b-alert class="alert-page-title" show variant="light">
      <div class="tag-list">
        <b-form-checkbox v-model="my_matches_only" name="check-button" button>
          {{ $t('SEARCH.filters.my_matches_only') }}
        </b-form-checkbox>
      </div>

      <div class="tag-list">
        <h6
          v-b-tooltip.hover
          :title="$t('SEARCH.filters.maps_title')"
        >{{ $t('SEARCH.filters.maps') }}</h6>
        <b-badge
          :variant="`${selectedMap == map ? 'primary' : 'light'}`"
          @click="filterMap(map)"
          v-for="map in maps"
          :key="map"
        >{{map}}</b-badge>
      </div>

      <div class="tag-list">
        <h6
          v-b-tooltip.hover
          :title="$t('SEARCH.filters.economy_title')"
        >{{ $t('SEARCH.filters.economy') }}</h6>
        <b-badge
          :variant="`${selectedEconomy == economy ? 'primary' : 'light'}`"
          @click="filterEconomy(economy)"
          v-for="economy in economies"
          :key="economy"
        >{{economy}}</b-badge>
      </div>

      <div class="tag-list" v-if="['force_buy', 'semi_eco', 'eco'].includes(selectedEconomy)">
        <h6
          v-b-tooltip.hover
          :title="$t('SEARCH.filters.trouble_title')"
        >{{ $t('SEARCH.filters.trouble') }}</h6>
        <b-badge
          :variant="`${selectedTrouble == trouble ? 'primary' : 'light'}`"
          @click="filterTrouble(trouble)"
          v-for="trouble in troubles"
          :key="trouble"
        >{{trouble}}</b-badge>
      </div>

      <div class="tag-list" v-if="false">
        <h6 v-b-tooltip.hover 
          :title="$t('SEARCH.filters.tags_title')"
          {{ $t('SEARCH.filters.tags') }}
        </h6>
        <b-badge
          :variant="`${tags.indexOf(tag.name) > -1 ? 'primary' : 'light'}`"
          @click="filterTag(tag.name)"
          v-for="tag in commonTags"
          :key="tag.id"
        >{{tag.name}}</b-badge>
      </div>
    </b-alert>

    <div class="stats-table table-responsive">
      <table class="table is-hoverable">
        <thead>
          <tr>
            <th>{{ $t('UTILITY.matches') }}</th>
            <th style="width: 50px">{{ $t('UTILITY.round') }}</th>
            <th style="width: 200px">{{ $t('UTILITY.winner') }}</th>
            <th>{{ $t('UTILITY.timeline') }}</th>
            <th>{{ $t('UTILITY.tags') }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="round in results" :key="round.id">
            <td v-if="round.match">
              <div class="match-single" v-if="round.match">
                <router-link
                  :to="{ name: 'MatchSingle', params: { id: round.match.id }}"
                  class="forum-link"
                >
                  <div class="team-column" v-if="round.match.team_a && round.match.team_b">
                    <div v-if="round.match.team_a" class="team">{{round.match.team_a.name}}</div>x
                    <div v-if="round.match.team_b" class="team">{{round.match.team_b.name}}</div>
                  </div>
                  <p class="score">{{ round.match.team_a_score }}:{{ round.match.team_b_score }}</p>
                  <p v-html="round.match.name || $t('UTILITY.unnamed') "></p>
                  <p v-html="round.match.map"></p>
                </router-link>
              </div>
            </td>
            <td>
              <div class="round-single">
                <router-link :to="{ name: 'RoundSingle', params: { id: round.id }}">
                  <h4 v-html="round.round_number || '-' "></h4>
                </router-link>
                <i
                  v-b-tooltip.hover
                  @click="openAddFavoriteModal('MatchRound', round.id)"
                  :title="$t('SEARCH.collection_modal.title')"
                  class="fas fa-folder-plus"
                  v-if="isLoggedIn && currentUser.role && currentUser.role == 'role_user_admin'"
                ></i>
              </div>
            </td>
            <td>
              {{ friendlyWinner(round.winner) }}
              <br>
              {{ friendlyReason(round.reason) }}
            </td>
            <td class="timeline-column">
              <router-link :to="{ name: 'RoundSingle', params: { id: round.id }}">
                <div
                  class="timeline-container"
                  :data-startTick="round.round_start_tick"
                  :data-endTick="round.round_end_tick"
                >
                  <div
                    class="timeline-events"
                    v-if="round.round_important_events && round.round_important_events.length < 20 && round.round_important_events.length > 0"
                  >
                    <div
                      :style="`left: ${calculateTimelineEventLeft(round.round_start_tick, round.round_end_tick, event.tick)}px`"
                      class="event"
                      v-for="event in round.round_important_events"
                      :key="event.id"
                    >
                      <span
                        :class="event.event_name"
                        v-b-tooltip.hover
                        :title="convertTickToRoundSeconds(round.round_start_tick, round.round_end_tick, event.tick, round.demofile_ticks_per_second)"
                      >
                        <i class="fas fa-skull" v-if="event.event_name == 'player_death'"></i>
                        <i class="fas fa-bomb" v-if="event.event_name == 'bomb_planted'"></i>
                      </span>
                      <!-- calculateTimelineEventLeft(startTick, endTick, eventTick) { -->
                    </div>
                  </div>
                </div>
              </router-link>
            </td>

            <td>
              <b-badge size="sm" variant="dark" v-if="round.round_type">
                {{ round.round_type.replace('round_', '') }}
                <span
                  v-if="round.side_trouble && ['round_force_buy', 'round_semi_eco', 'round_eco'].includes(round.round_type)"
                >{{ round.side_trouble.replace('trouble_', '') }}</span>
              </b-badge>
              <div class="tag-list" v-if="round.tag_list && round.tag_list.length > 0">
                <b-badge variant="light" v-for="tag in round.tag_list" :key="tag" v-html="tag"></b-badge>
              </div>
            </td>
          </tr>

          <tr v-if="results.length == 0">
            <td colspan="6">
              <p class="text-center">
                {{ $t('SEARCH.no_rounds_found') }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-pagination
      size="sm"
      @change="changePage"
      align="center"
      :total-rows="totalRecords"
      v-model="page"
      :per-page="perPage"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: "SearchResult",
  data() {
    return {
      collections: [],
      favorite: {
        name: "",
        description: "",
        favorable_id: "",
        favorable_type: "",
        collection: ""
      },
      results: [],
      commonTags: [],
      tags: [],
      my_matches_only: false,
      round_to_tag: "",
      selectedMap: "",
      selectedEconomy: "",
      economies: ["pistol", "eco", "semi_eco", "force_buy", "normal"],
      selectedTrouble: "",
      troubles: ["terrorists", "cts"],
      maps: [
        "de_cache",
        "de_inferno",
        "de_mirage",
        "de_dust2",
        "de_nuke",
        "de_overpass",
        "de_train",
        "de_vertigo"
      ],
      page: 1,
      perPage: 0,
      totalRecords: 0
    };
  },
  methods: {
    initialize() {
      let params = new URLSearchParams(location.search);
      if (params.get("page")) this.page = parseInt(params.get("page"));
      if (params.get("map")) this.selectedMap = params.get("map");
      if (params.get("my_matches_only")) this.my_matches_only = params.get("my_matches_only");
      
      if (params.get("round_type"))
        this.selectedEconomy = params.get("round_type");
      if (params.get("side_trouble"))
        this.selectedTrouble = params.get("side_trouble");
      if (params.get("tag_list")) {
        this.tags = params.get("tag_list").split(",");
      }

      this.search();

      this.$http
        .get("common_tags.json")
        .then(response => (this.commonTags = response.data));

      this.$http
        .get("collections.json")
        .then(response => (this.collections = response.data));
    },

    search() {
      const params = new URLSearchParams(location.search);

      var tagList = "";
      if (this.tags.length > 0) tagList = this.tags.join(",");
      else tagList = "";

      var searchParams = [];
      params.set("tag_list", tagList);
      params.set("map", this.selectedMap);
      params.set("page", this.page);
      params.set("round_type", this.selectedEconomy);
      params.set("side_trouble", this.selectedTrouble);
      params.set("my_matches_only", this.my_matches_only);

      window.history.replaceState({}, "", `${location.pathname}?${params}`);

      this.$http.get(`search.json?${params}`).then(response => {
        this.results = response.data.records;
        this.totalRecords = response.data.total_records;
        this.perPage = response.data.per_page;
        if (this.totalRecords <= this.perPage && this.page > 1) {
          this.page = 1;
          this.search();
        }
      });
    },

    changePage(page) {
      this.page = page;
      this.search();
    },

    filterTag(tagName) {
      if (this.tags.indexOf(tagName) > -1)
        this.tags = this.tags.filter(x => x != tagName);
      else this.tags.push(tagName);

      this.search();
    },

    filterMap(mapName) {
      if (this.selectedMap == mapName) this.selectedMap = "";
      else this.selectedMap = mapName;
      this.search();
    },

    filterEconomy(economy) {
      if (this.selectedEconomy == economy) this.selectedEconomy = "";
      else this.selectedEconomy = economy;
      this.search();
    },

    filterTrouble(trouble) {
      if (this.selectedTrouble == trouble) this.selectedTrouble = "";
      else this.selectedTrouble = trouble;
      this.search();
    },

    calculateTimelineEventLeft(startTick, endTick, eventTick) {
      var timelinePx = 400;
      var totalTicks = endTick - startTick;

      var tickPx = timelinePx / totalTicks;
      var left = (eventTick - startTick) * tickPx;
      return left - 40;
    },

    convertTickToRoundSeconds(startTick, endTick, eventTick, ticksPerSecond) {
      var ticksPerSecond = ticksPerSecond == null ? 128 : ticksPerSecond;

      var ms = ((eventTick - startTick) * 1000) / ticksPerSecond;

      var minutes = Math.floor(ms / 60000);
      var seconds = ((ms % 60000) / 1000).toFixed(0);
      return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    },

    openAddFavoriteModal(type, id) {
      this.favorite.favorable_type = type;
      if (type == "Match") this.favorite.favorable_id = this.$route.params.id;
      else if (type == "MatchRound") this.favorite.favorable_id = id;

      this.$refs.modalAddFavorite.show();
    },

    handleSubmit() {
      this.$http
        .post(
          `collections/${this.favorite.collection}/collection_favorites.json`,
          this.favorite
        )
        .then(response => {
          this.$refs.modalAddFavorite.hide();
        });
    },
  },
  mounted() {
    this.initialize();
  },
  watch: {
    "$route.params.id": function(id) {
      this.initialize();
    },
    "my_matches_only": function(boolValue) {
      this.search();
    }
  }
};
// Header
</script>

<style lang="sass" scoped>
.timeline-column
  width: 50%
  overflow: hidden
  .timeline-container
    position: relative
    width: 400px
    height: 4px
    background: #d1d1d1
    top: 20px
  
    .timeline-events
      position: relative
      top: -10px
      .event
        position: absolute
        span
          display: block
          color: #000
          &.player_death
            
          &.bomb_planted

a:hover
  text-decoration: none
  color: #333
.match-single
  p
    margin: 0
.team-column
  display: flex
  // justify-content: space-around
  div
    font-size: 18px
    font-weight: bold
    &:first-child
      margin-right: 5px
    &:last-child
      margin-left: 5px
.tag-list
  &:not(:last-child)
    margin-bottom: 20px
  h6
    // font-weight: bold
  .badge
    margin-right: 4px
    cursor: pointer
</style>
